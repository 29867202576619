// widget
import { Elm } from "../widget/src/Main.elm"

// styles
import "./styles/main.scss"  // wraps bulma

import appStoreBadge from "./images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg"
import circledArrowRightIcon from "./images/circled_arrow_right_icon.svg"
import compassAppIcon from "./images/compass_app_icon.svg"

const app = Elm.Main.init({
  node: document.getElementById("elm"),
  flags: {
    assets: {
      appStoreBadge,
      circledArrowRightIcon,
      compassAppIcon
    },
    seed: Date.now()
  }
});

const sendSizeInfo = (function() {
  // closure variables...

  // CANVAS
  // TODO: pass in canvas id as flag instead of hard coded 'canvas' in two places?
  const svgNode = document.getElementById("canvas");

  // 1 == 1x1,     2 == 2x1,      3 == 2.5x1,    4 == 3x1
  let mode = 3;

  return () => {
    if (svgNode) {
      const svgBounds = svgNode.getBoundingClientRect();

      // align with bulma
      const queryWidescreen = window.matchMedia("(min-width: 1216px)");
      const queryDesktop = window.matchMedia("(min-width: 1088px)");
      const queryTablet = window.matchMedia("(min-width: 769px)");
      if (queryWidescreen.matches) {
        // container.classList.remove("is-hidden")
        if (mode !== 4) {
          app.ports.resizeCanvas.send({
            x: svgBounds.x,
            y: svgBounds.y,
            width: 1458,
            height: 486
          });
          mode = 4;
        }
      } else if (queryDesktop.matches) {
        // container.classList.add("is-hidden")
        if (mode !== 3) {
          app.ports.resizeCanvas.send({
            x: svgBounds.x,
            y: svgBounds.y,
            width: 1215,
            height: 486
          });
          mode = 3;
        }
      } else if (queryTablet.matches) {
        // container.classList.add("is-hidden")
        if (mode !== 2) {
          app.ports.resizeCanvas.send({
            x: svgBounds.x,
            y: svgBounds.y,
            // width: 864,
            width: 972,
            height: 486
          });
          mode = 2;
        }
      } else {
        // container.classList.add("is-hidden")
        if (mode !== 1) {
          app.ports.resizeCanvas.send({
            x: svgBounds.x,
            y: svgBounds.y,
            width: 486,
            height: 486
            //height: 810
            //height: 648
          });
          mode = 1;
        }
      }
      const msg = {
        x: svgBounds.x,
        y: svgBounds.y,
        width: svgBounds.width,
        height: svgBounds.height
      };
      // console.log('sending bounds on resize', svgBounds);
      app.ports.adjustCanvasToView.send(msg);
    }
  };
})();

// send initial size...
sendSizeInfo();

window.addEventListener("resize", () => {
  // send new size...
  sendSizeInfo();
});

const sendScrollUpdate = (function() {
  // closure variables...

  // CANVAS
  // TODO: pass in canvas id as flag instead of hard coded 'canvas' in two places?
  const svgNode = document.getElementById("canvas");

  let ticking = false;

  return () => {
    if (svgNode && !ticking) {
      window.requestAnimationFrame(() => {
        ticking = false;
        const svgBounds = svgNode.getBoundingClientRect();
        // console.log('sending bounds on scroll', svgBounds);
        const msg = {
          x: svgBounds.x,
          y: svgBounds.y,
          width: svgBounds.width,
          height: svgBounds.height
        };
        app.ports.adjustCanvasToView.send(msg);
      });

      ticking = true;
    }
  }
})()

window.addEventListener('scroll', () => {
  sendScrollUpdate()
});

let animating = true;
function step(timestamp) {
  if (animating) {
    app.ports.stepAnimationFrame.send(timestamp);
    window.requestAnimationFrame(step);
  }
}

app.ports.toggleAnimation.subscribe(enabled => {
  console.log(`toggling animation: ${enabled}`);
  if (!animating && enabled) {
    window.requestAnimationFrame(step);
  }
  animating = enabled;
});

window.requestAnimationFrame(step);

// expose app for console interaction
window.app = app;

window.openModal = () => {
  app.ports.toggleComingSoonModal.send(true);
}